<template>
  <b-col cols="12">
    <div class="d-flex justify-content-center mb-1 mt-3 w-100" v-if="isLoading">
      <b-spinner label="Loading..." />
    </div>
    <template v-else>
      <b-card v-if="!isProfileComplete" class="px-33 py-1" style="background: #fff4ec; border-radius: 8px" no-body>
        <div class="d-flex align-items-center">
          <div style="
              width: 30px;
              height: 30px;
              background: #ff8f39;
              opacity: 0.75;
              border-radius: 50%;
            " />
          <span class="text-dark fw-bold-500 size14 ml-1">Anda belum melengkapi informasi toko, silahkan isi form di
            bawah
            ini untuk melengkapi informasi toko.</span>
        </div>
      </b-card>

      <h4 class="text-black-2 fw-bold-700 size16 mb-1">Profil Toko</h4>
      <b-card class="border-8" no-body>
        <div class="d-flex align-items-center justify-content-between px-33 py-2">
          <div class="d-flex flex-column">
            <h2 class="text-dark text-darken-4 fw-bold-800">
              {{ (merchant && merchant.name) || "Nama Toko" }}
            </h2>
            <!-- <span class="text-dark text-darken-5 fw-bold-400 mb-1">{{ merchant.address || 'Jln. Alamat, Kota' }}</span> -->
            <div v-if="!isProfileComplete">
              <b-button v-if="checkPermission('edit profil toko')" class="border-8" @click="editItem">
                <b-img :src="require('@/assets/images/icons/Process2-white.svg')" alt="icon process" />
                Lengkapi sekarang
              </b-button>
            </div>
            <div v-else>
              <div v-if="
                this.merchant && this.merchant.ecommerce_username !== null
              ">
                <div class="text-primary">Link Toko Digital:</div>
                <a class="text-dark text-darken-4" :href="ecommerceUrl + `/${merchant.ecommerce_username}`" target="_blank">
                  {{ ecommerceUrl + `/${merchant.ecommerce_username}` || "Link Ecommerce" }}
                </a>
                <feather-icon class="text-dark text-darken-4 font-weight-bolder ml-1 cursor-pointer" size="16"
                  icon="CopyIcon" @click="copyURL" />
                <small v-if="copied" class="text-primary ml-1">Berhasil disalin!</small>
              </div>
              <b-button v-if="checkPermission('edit profil toko')" class="border-8 bg-white mt-1" @click="editItem">
                <b-img :src="require('@/assets/images/icons/Process2.svg')" alt="icon process" />
                Ubah profile
              </b-button>
              <div class="mt-2">
                <label class="text-dark text-darken-4 fw-bold-500 size14">
                  Daftar Cabang
                </label>
                <h6 class="text-dark size18 fw-bold-600">
                  <ul style="padding: 0; list-style-position: inside">
                    <li v-for="(branch, index) in branches" :key="index" class="d-flex align-items-center mb-25"
                      style="gap: 16px">
                      <p class="mb-0 size14">
                        {{ branch.name }} - Masa Aktif Cabang
                        {{ branch.remaining_renewal_days }} Hari
                      </p>
                      <!-- <div class="remaining_days">
                      Masa aktif cabang (
                      {{ branch.remaining_renewal_days }} Hari)
                    </div> -->
                    </li>
                  </ul>
                </h6>
              </div>
            </div>
          </div>
          <div v-if="this.merchant && this.merchant.ecommerce_username !== null">
            <a :href="qrCodeImage" download="qrcode.png">
              <b-img :src="qrCodeImage" alt="QR Code" />
            </a>
          </div>
          <!-- <div
          :style="`background-image: url(${profile.merchant && profile.merchant.logo || ''});
          background-size: contain; background-repeat: no-repeat; width: 120px; height: 120px; border-radius: 8px;`"
        /> -->
        </div>
        <!-- <hr class="my-0"> -->
        <!-- <b-collapse
        id="collapse-1"
      >
        <DetailProfile :merchants="merchant" :branches="branches" />
      </b-collapse>
      <div
        v-if="is_complete"
        v-b-toggle.collapse-1
        class="d-flex justify-content-between align-items-center p-1 border-top"
        style="cursor: pointer;"
        @click="is_hide = !is_hide"
      >
        <span>{{ is_hide == false ? 'Lihat selengkapnya' : 'Sembunyikan' }}</span>
        <feather-icon :icon="is_hide == false ? 'ChevronRightIcon' : 'ChevronUpIcon'" />
      </div> -->
      </b-card>
      <ModalAddProfile :get-data="getMerchants" :form-data="formPayload" :photo-url="photo_url"
        :get-profile="getProfiles" :profile="profile" />
    </template>
  </b-col>
</template>

<script>
/* eslint-disable no-constant-condition */
import {
  BButton,
  BImg,
  BCol,
  BCard,
  BCollapse,
  VBToggle,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
} from "@/auth/utils";
import ModalAddProfile from "@/components/Master/Toko/Modal/ModalAddProfile.vue";
import DetailProfile from "@/components/Master/Toko/Part/DetailProfile.vue";

import QRCode from "qrcode";

export default {
  components: {
    BButton,
    BImg,
    BRow,
    BCol,
    BCard,
    BCollapse,
    DetailProfile,
    ModalAddProfile,
    BSpinner,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  data() {
    const ecommerceUrl = process.env.ECOMMERCE_URL || ''
    return {
      ecommerceUrl,
      is_hide: false,
      merchant: {},
      branches: {},
      profile: {},
      is_complete: null,
      photo_url: "",
      formPayload: {
        name: "",
        // address: '',
        // name_in_invoice: '',
        // alias_name: '',
        // alias_address: '',
        // city: '',
        // postal_code: '',
        // address_latitude: '',
        // address_longitude: '',
      },
      copied: false,
      qrCodeImage: "",
      isLoading: false,
    };
  },
  created() {
  },
  computed: {
    ecommerce_url() {
      if (
        this.merchant &&
        this.merchant.ecommerce_username &&
        process.env.NODE_ENV === "development"
      ) {
        return `https://toqoo-ecommerce.vercel.app/${this.merchant.ecommerce_username}`;
      } else if (
        this.merchant &&
        this.merchant.ecommerce_username &&
        process.env.NODE_ENV === "staging"
      ) {
        return `https://toqoo-ecommerce-staging.netlify.app/${this.merchant.ecommerce_username}`;
      } else if (
        this.merchant &&
        this.merchant.ecommerce_username &&
        process.env.NODE_ENV === "production"
      ) {
        return `https://ecommerce.toqoo.id/${this.merchant.ecommerce_username}`;
      } else {
        return "";
      }
    },
  },
  watch: {
    ecommerce_url: "generateQRCode",
  },
  mounted() {
    this.isProfileComplete();
    this.getProfiles();
    this.getMerchants();
    this.getBranches();
  },
  methods: {
    // copyQRCodeImage() {
    //   if (this.qrCodeImage) {
    //     const canvas = document.createElement('canvas');
    //     const image = new Image();
    //     image.src = this.qrCodeImage;

    //     image.onload = () => {
    //       canvas.width = image.width;
    //       canvas.height = image.height;

    //       const ctx = canvas.getContext('2d');
    //       ctx.drawImage(image, 0, 0, image.width, image.height);

    //       canvas.toBlob((blob) => {
    //         if (blob) {
    //           const clipboardData = new ClipboardItem({ 'image/png': blob });
    //           navigator.clipboard.write([clipboardData])
    //             .then(() => {
    //               this.copied = true;
    //               setTimeout(() => {
    //                 this.copied = false;
    //               }, 1500);
    //             })
    //             .catch((error) => {
    //               console.error('Error copying image:', error);
    //             });
    //         }
    //       }, 'image/png');
    //     };
    //   }
    // },
    copyURL() {
      const url = this.ecommerce_url; // Use the computed property here

      if (url) {
        const tempInput = document.createElement("input");
        tempInput.value = url;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);

        this.copied = true;
        setTimeout(() => {
          this.copied = false;
        }, 1500);
      }
    },
    generateQRCode() {
      const url = this.ecommerce_url;

      if (url) {
        QRCode.toDataURL(url, (err, dataUrl) => {
          if (err) {
            console.error("Error generating QR code:", err);
          } else {
            this.qrCodeImage = dataUrl;
          }
        });
      }
    },
    clearItem() {
      this.photo_url = "";
      this.formPayload = {
        name: "",
        ecommerce_username: "",
        // address: '',
        // name_in_invoice: '',
        // alias_name: '',
        // alias_address: '',
        // city: '',
        // postal_code: '',
        // address_latitude: '',
        // address_longitude: '',
      };
    },
    editItem() {
      this.clearItem();
      this.photo_url = this.merchant.logo;
      this.formPayload = {
        name: this.merchant.name,
        ecommerce_username: this.merchant.ecommerce_username,
        // address: this.merchant.address,
        // name_in_invoice: this.merchant.name_in_invoice,
        // alias_name: this.merchant.alias_name,
        // alias_address: this.merchant.alias_address,
        // city: this.merchant.city,
        // postal_code: this.merchant.postal_code,
        // address_latitude: this.merchant.address_latitude,
        // address_longitude: this.merchant.address_longitude,
      };
      this.$bvModal.show("modal-profile");
    },
    isProfileComplete() {
      if (
        [
          this.merchant.name,
          this.merchant.ecommerce_username,
          // this.merchant.slug,
          // this.merchant.logo,
          // this.merchant.address,
          // this.merchant.alias_address,
          // this.merchant.alias_name,
          // this.merchant.city,
          // this.merchant.name_in_invoice,
          // this.merchant.postal_code,
        ].some((x) => x === null)
      ) {
        this.is_complete = false;
        // return false
      }
      this.is_complete = true;
      // return true
    },
    getProfiles() {
      this.isLoading = true;
      this.$store
        .dispatch("profile/getProfile")
        .then((result) => {
          this.profile = result.data.data;
          this.isLoading = false;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          this.isLoading = false;
        });
    },
    getMerchants() {
      this.$store
        .dispatch("profile/getMerchant")
        .then((result) => {
          this.merchant = result.data.data;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    getBranches() {
      this.$store
        .dispatch("cabang/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          this.branches = result.data.data.data;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.remaining_days {
  background: #000;
  font-size: 12px;
  color: #ffff;
  padding: 10px;
  border-radius: 16px;
}
</style>
