<template>
    <b-row>
        <b-col cols="12">
            <h4 class="text-black-2 fw-bold-700 size16 mb-1">
                Status Berlangganan
            </h4>
        </b-col>
        <b-col cols="12">
            <b-card class="border-8" no-body v-if="merchantData.renewal_status === 'subscribe'">
                <div class="px-33 py-2">
                    <div class="bg-primary text-white px-4 py-1 fw-bold-500 border-6 mb-2">
                        Paket Berlangganan - {{ getRemainingDays() }}
                    </div>
                </div>
            </b-card>
            <b-card class="border-8" no-body v-if="merchantData.renewal_status === 'trial'">
                <div class="px-33 py-2">
                    <div class="bg-gray-4 text-black-2 px-4 py-1 fw-bold-500 border-6 mb-2">
                        Free Trial - {{ getRemainingDays() }}
                    </div>
                    <b-button class="bg-secondary w-100 py-1 size14 fw-bold-500 text-center text-white border-6"
                        @click="openModal">
                        Mulai Berlangganan
                    </b-button>
                </div>
            </b-card>
            <b-card class="border-8" no-body v-if="merchantData.renewal_status === 'free'">
                <div class="px-33 py-2">
                    <div class="bg-gray-4 text-black-2 px-4 py-1 fw-bold-500 border-6 mb-2">
                        Free
                    </div>
                    <b-button class="bg-secondary w-100 py-1 size14 fw-bold-500 text-center text-white border-6"
                        @click="openModal">
                        Mulai Berlangganan
                    </b-button>
                </div>
            </b-card>
        </b-col>
        <ModalSubscribe />
    </b-row>
</template>

<script>
import {
    BRow, BButton, BImg, BCol, BCard
} from 'bootstrap-vue'
import ModalSubscribe from './Modal/ModalSubscribe.vue';

export default {
    components: {
        BRow,
        BButton,
        BImg,
        BCol,
        BCard,
        ModalSubscribe
    },
    props: {
        getDataMerchant: {
            type: Function,
        },
        merchantData: {
            type: Object,
        }
    },
    methods: {
        openModal() {
            this.$bvModal.show('modal-subscribe')
        },
        getSubscriptionStatus() {
            return this.merchantData.renewal_status === 'trial' ? 'Free Trial - ' : 'Free';
        },
        getRemainingDays() {
            const endDate = new Date(this.merchantData.renewal_end_date);
            const today = new Date();
            const timeDifference = endDate - today;
            const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
            return daysRemaining > 0 ? `Tersisa ${daysRemaining} hari` : '';
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables/_variables.scss';
</style>